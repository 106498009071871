import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import GridContainer from './Grid/GridContainer';

export default function Footer() {
  return (
    <GridContainer>
      <footer className="py-2 my-4">
        <ul className="nav justify-content-center border-bottom pb-3 mb-3">
          <li className="nav-item"><a href="https://www.instagram.com/meupotinho.br/" target="_blank" className="nav-link px-2 text-body-secondary"><FontAwesomeIcon icon={faInstagram} size='2x' /></a></li>
        </ul>
        <p className="text-center text-body-secondary">© 1999 - {1900 + new Date().getYear()} SAVHAC - Industria de Alimentos. Todos os direitos reservados.</p>
      </footer>
    </GridContainer>
  );
}