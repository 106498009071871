import Head from 'next/head'
import Image from 'next/image'
import { Inter } from 'next/font/google'
import styles from '@/styles/Home.module.css'
import Footer from '@/components/Layout/Footer'
import GridContainer from '@/components/Layout/Grid/GridContainer'
import GridItem from '@/components/Layout/Grid/GridItem'

const inter = Inter({ subsets: ['latin'] })

export default function Home() {
  return (
    <>
      <Head>
        <title>Meu Potinho</title>
        <meta property="og:title" content="Meu Potinho"></meta>
        <meta property="twitter:title" content="Meu Potinho"></meta>
        <meta property="description" content="Meu Potinho" />
        <meta property="og:description" content="Meu Potinho" />
        <meta property="twitter:description" content="Meu Potinho"></meta>
        <meta property="og:url" content="https://www.meupotinho.com.br"></meta>
        <meta property="og:image" content="https://www.meupotinho.com.br/img/logo_meupotinho.png"></meta>
        <meta property="twitter:image" content="https://www.meupotinho.com.br/img/logo_meupotinho.png"></meta>
      </Head>
      <GridContainer>
        <main className={`${styles.main} ${inter.className}`}>
          <GridContainer fluid rows>
            <GridItem>
              <Image
                className={styles.logo}
                src="/img/logo_meupotinho_grande.png"
                alt="Meu Potinho"
                width={600}
                height={600}
                priority
              />
            </GridItem>
          </GridContainer>
        </main>
      </GridContainer>
      <Footer />
    </>
  )
}